.AboutPage {
    text-align: left;
}

.AboutTitle {
    text-align: left;
    padding-bottom: 1%;
    padding-top: 5vh;
}

.AboutDivider {
    padding-bottom: 2vh;
}
