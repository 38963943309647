:root {
    --primary-main: #394047;
    --primary-light: #8b9bab;
}

.HomePage {
    text-align: center;
    width: 55vw;
    margin: auto;
}

.HomeContent {
    padding-top: 15vh;
    padding-bottom: 5vh;
}

.Name {
    text-align: left;
}

.Introduction {
    margin-top: 5vh;
    text-align: left;
}

.Icons {
    margin-top: 5vh;
}

.Link {
    text-decoration: none;
    color: var(--primary-main);
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.Link:hover {
    text-decoration: none;
    color: var(--primary-light);
}

.BottomBar {
    margin-top: 10vh;
    margin-bottom: 1vh;
}
